import { Injectable } from '@angular/core';
import { Helper } from '@horizon/dm-core';
import { BehaviorSubject, Observable } from 'rxjs';
import { DynamicApiService } from './dynamic-api.service';
import { UtilsService } from '@horizon/core';

@Injectable({
  providedIn: 'root',
})
export class TemplatesDataService {
  private $templates: BehaviorSubject<any> = new BehaviorSubject<any>({});
  private $templatesReferences: BehaviorSubject<any> = new BehaviorSubject<any>(
    {}
  );
  private $itemsByTemplates: BehaviorSubject<any> = new BehaviorSubject<any>(
    {}
  );

  constructor(
    private dynamicApi: DynamicApiService,
    private utils: UtilsService
  ) {}

  // TEMPLATES
  // -----------------------------------------------------------------------------
  public setTemplates(templates: any): void {
    this.$templates.next(templates);
  }

  public getTemplatesChanges(): Observable<any> {
    return this.$templates.asObservable();
  }

  public _templates(): any {
    return this.$templates.getValue();
  }

  // TEMPLATES REFERENCES
  // -----------------------------------------------------------------------------
  public setTemplatesReferences(templatesReferences: any): void {
    this.$templatesReferences.next(templatesReferences);
  }

  public getTemplatesReferencesChanges(): Observable<any> {
    return this.$templatesReferences.asObservable();
  }

  public _templatesReferences(): any {
    return this.$templatesReferences.getValue();
  }

  // ITEMS BY TEMPLATES
  // -----------------------------------------------------------------------------
  public setItemsByTemplates(itemsByTemplates: any): void {
    this.$itemsByTemplates.next(itemsByTemplates);
  }

  public getItemsByTemplatesChanges(): Observable<any> {
    return this.$itemsByTemplates.asObservable();
  }

  public _itemsByTemplates(): any {
    return this.$itemsByTemplates.getValue();
  }

  // AUX FUNCTIONS
  // -----------------------------------------------------------------------------
  public async fetchForNeededTemplates(layout): Promise<any> {
    const templates = Object.keys(this._templates()).map((key) => {
      return key;
    });
    const templatesToFetch = [];
    layout.forEach((_) => {
      if (_?.fields) {
        _.fields.forEach((field) => {
          if (
            !templates.includes(field.template) &&
            !templatesToFetch.includes(field.template)
          )
            templatesToFetch.push(field.template);
        });
      } else if (_?.elements) {
        _.elements?.forEach((elm) => {
          if (elm?.fields) {
            elm.fields.forEach((field) => {
              if (
                !templates.includes(field.template) &&
                !templatesToFetch.includes(field.template)
              )
                templatesToFetch.push(field.template);
            });
          } else if (elm.templates) {
            elm.templates.forEach((temp) => {
              if (!templates.includes(temp) && !templatesToFetch.includes(temp))
                templatesToFetch.push(temp);
            });
          }
        });
      }
    });

    if (templatesToFetch?.length) {
      console.log('New needed templates:', templatesToFetch);
      await this.getTemplates(templatesToFetch);
      this.getTemplatesReferenceCollections();
    }

    return templatesToFetch;
  }

  public getTemplatesReferenceCollections(): void {
    Object.keys(this._templates()).forEach((tempKey) => {
      this.getReferences(tempKey);
    });
  }

  private getReferences(parent: string) {
    const referenceCollections = this._templates()[parent].fields.filter(
      (fld) => Helper.isReferenceType(fld)
    );

    referenceCollections.forEach(async (ref) => {
      await this.handleTemplatesReferences(
        parent,
        ref.name,
        await this.getTemplateReferences(parent, ref.name)
      );
    });
  }

  private fetchChildInfo(itemsByTemplates: any) {
    const templates = this._templates();
    Object.keys(templates).map((template) => {
      const childFields = templates[template].fields.filter(
        (_) => _.type === 'child'
      );
      if (childFields?.length) {
        childFields.map(async (child) => {
          if (
            itemsByTemplates[template]?.fields[child.name] !== null &&
            itemsByTemplates[template]?.fields[child.name]
          ) {
            const item = await this.dynamicApi.getItem(
              this.toCamelCase(child.reference.template),
              itemsByTemplates[template]?.fields[child.name]
            );
            itemsByTemplates[this.toCamelCase(child.reference.template)] =
              item[0];
          } else {
            if (
              itemsByTemplates.hasOwnProperty(
                this.toCamelCase(child.reference.template)
              )
            ) {
              itemsByTemplates[this.toCamelCase(child.reference.template)] =
                null;
            }
          }
        });
      }
    });
  }

  private async handleTemplatesReferences(
    parentTempate: string,
    referenceName: string,
    newReferenceData: any[]
  ): Promise<void> {
    //In case the template of the reference is not known yet
    const parentReferenceTemplate =
      newReferenceData.length > 0
        ? this.utils.toCamelCase(newReferenceData[0].template)
        : null;
    if (
      parentReferenceTemplate &&
      !this._templates()?.parentReferenceTemplate
    ) {
      await this.getTemplates([parentReferenceTemplate]);
    }

    let templatesReferences = this._templatesReferences();
    if (!templatesReferences?.[parentTempate]) {
      templatesReferences = {
        ...templatesReferences,
        [parentTempate]: {},
      };
      templatesReferences[parentTempate] = {
        ...templatesReferences[parentTempate],
        [referenceName]: {},
      };
    } else if (!templatesReferences[parentTempate]?.[referenceName]) {
      templatesReferences[parentTempate] = {
        ...templatesReferences[parentTempate],
        [referenceName]: {},
      };
    }
    templatesReferences[parentTempate][referenceName] = newReferenceData;

    this.setTemplatesReferences(templatesReferences);
  }

  public async getTemplates(templatesToFetch: string[]): Promise<any> {
    const templatesObject = {};
    await new Promise(async (resolve, reject) => {
      this.dynamicApi
        .getTemplate(templatesToFetch)
        .then((result: any) => {
          result.forEach((resultItem, index) => {
            templatesObject[templatesToFetch[index]] = resultItem;
          });

          resolve(templatesObject);
        })
        .catch(() => {});
    });
    this.setTemplates({
      ...this._templates(),
      ...templatesObject,
    });
  }

  public async getTemplatesResolved(templatesToFetch: string[]): Promise<any> {
    const templatesObject = {};
    await new Promise(async (resolve, reject) => {
      this.dynamicApi
        .getTemplate(templatesToFetch)
        .then((result: any) => {
          result.forEach((resultItem, index) => {
            templatesObject[templatesToFetch[index]] = resultItem;
          });

          resolve(templatesObject);
        })
        .catch(() => {});
    });
    return templatesObject;
  }

  public async getChildTemplatesForParent(parentTemplates: any): Promise<any> {
    let childTemplates = {};
    await new Promise(async (resolve, reject) => {
      Object.keys(parentTemplates).map((template) => {
        const childFields = parentTemplates[template].fields.filter(
          (_) => _.type === 'child'
        );
        if (childFields?.length) {
          childFields.map(async (child) => {
            const valTemplate = await this.dynamicApi.getTemplate(
              child.name.toLowerCase()
            );
            childTemplates[child.name.toLowerCase()] = valTemplate[0];
            resolve(childTemplates);
          });
        } else {
          resolve(childTemplates);
        }
      });
    });
    return childTemplates;
  }

  public async getChildTemplates(parentTemplates: any): Promise<any> {
    let childTemplates = {};
    Object.keys(parentTemplates).map((template) => {
      const childFields = parentTemplates[template].fields.filter(
        (_) => _.type === 'child'
      );
      if (childFields?.length) {
        childFields.map(async (child) => {
          childTemplates[child.reference.template.toLowerCase()] =
            await this.dynamicApi
              .getTemplate(child.reference.template)
              .then((response) => {
                return response[0];
              })
              .catch(() => {});
        });
      }
    });
    return childTemplates;
  }

  public async getItems(itemsToFetch: string[], id: number) {
    const itemsByTemplates = {};
    let itemsIteration = new Promise(async (resolve, reject) => {
      itemsToFetch.forEach(async (item) => {
        this.dynamicApi
          .getItem(item, id)
          .then((result: any) => {
            result.forEach((resultItem, index) => {
              itemsByTemplates[itemsToFetch[index]] = resultItem;
            });
            resolve(itemsByTemplates);
          })
          .catch(() => {});
      });
    });
    itemsIteration.then(() => {
      this.setItemsByTemplates({
        ...this._itemsByTemplates(),
        ...itemsByTemplates,
      });
      this.fetchChildInfo(this._itemsByTemplates());
    });
  }

  public async getTemplateReferences(
    template: string,
    fieldName: string
  ): Promise<[]> {
    let context = {
      parent: template,
      referenceName: fieldName,
    };

    return new Promise(async (resolve, reject) => {
      this.dynamicApi
        .getReference(context)
        .then((result: any) => {
          resolve(result);
        })
        .catch(() => {});
    });
  }

  public toCamelCase = (str: string) => {
    return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function (match, index) {
      if (+match === 0) return ''; // or if (/\s+/.test(match)) for white spaces
      return index === 0 ? match.toLowerCase() : match.toUpperCase();
    });
  };
}
