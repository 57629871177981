<!-- PCIPal -->
<!-- ---------------------------------------------------------------------------------- -->
<!-- #region PCIPal IFRAME -->
<div class="pcipal-container" *>
  <iframe
    [ngClass]="!showPCIPal && 'pcipal-container-hidden'"
    id="pcipal"
    class="pcipal-container_iframe"
    name="pcipal_frame"
    sandbox="allow-scripts allow-same-origin"
  ></iframe>
  <form
    id="embedForm"
    name="embedForm"
    target="pcipal_frame"
    method="post"
    [action]="iframeActionUrl"
  >
    <input type="hidden" name="X-BEARER-TOKEN" [value]="accessToken" />

    <input type="hidden" name="X-REFRESH-TOKEN" [value]="refreshToken" />
  </form>
</div>
<!-- #endregion -->
