import { Component, ElementRef, NgZone, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { PolicyService, UsersService } from '@horizon/core';
import { User } from '@horizon/core';
import { TranslateService } from '@ngx-translate/core';
import { MenuRoutePaths, RoutePaths } from '@shared/enums/routes.enums';
import { EventFireService } from '@shared/services/event-fire.service';
import { AuthorizationService } from './services/authorization.service';
import { SearchApiService } from '../core/services/search.services';
import { layout } from './config/layout';
import { UtilsService } from '@horizon/core';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { DatePipe, Location } from '@angular/common';
import { AppSectionsPermissionsKey } from '@shared/enums/permissions.enums';
import { TemplatesDataService } from '@shared/services/templates-data.service';
import {
  DialogNotification,
  NotificationService,
  ToastNotificationItem,
  ToastService,
} from '@horizon/core-notifications';
import { globalSettings } from 'src/global-settings';
import { HeaderConfigService } from './config/header-config.service';
import { SideBarMenuConfigService } from './config/side-bar-menu-config.service';
import { LoanEngineApiService } from '@shared/services/loan-engine-api.service';
import { HeaderToolbarOption } from '@horizon/core-layouts';
import { ApiExceptionsHandlerService } from '@shared/services/api-exceptions-handler.service';
import { GenesysTelephonyService } from '@shared/components/horizon-genesys-telephony/services/genesys-telephony.service';
import { AccountDataService } from '@shared/services/account-data.service';
import { ValidationRulesService } from '@horizon/dm-core';
import { HorizonGenesysCallLogService } from '@shared/services/horizon-genesys-call-log.service';

@Component({
  selector: 'core',
  templateUrl: './core.component.html',
  styleUrls: ['./core.component.scss'],
})
export class CoreComponent implements OnInit {
  private readonly destroy$ = new Subject<void>();

  public loginShortDisplayName: string = undefined;
  public canUserSearch: boolean = true;
  public currentLanguage: string;
  public user: User;
  public userPhoto: SafeResourceUrl | null = null;

  public isIframe = window !== window.parent && !window.opener;
  public isLoginWanted: boolean = false;

  public toastNotifications: ToastNotificationItem[] = [];
  public notificationsDialog: DialogNotification;
  public showLoadingSpinner: boolean = false;
  public dataRecentsSearch: any;
  public globalSettings: any = globalSettings;
  //Menu permissions enums
  public menuRoutingPaths: any = MenuRoutePaths;
  public menuItemsPermissions: any = AppSectionsPermissionsKey;

  public showGenesysSubstituteModal: boolean = false;
  //Call log modal
  public callLogView;
  public showCallLog: boolean = false;

  recentSearchContent = this.utils.getLayoutElementById(
    layout,
    'recent-search'
  );
  public templateRecentSearch: any = {
    id: 'template',
    fields: [
      {
        name: 'anchored',
        type: 'icon',
      },
      {
        name: 'account',
        type: 'url',
      },
      {
        name: 'text',
      },
      {
        name: 'date',
        type: 'date',
      },
    ],
  };

  public systemDate: any;

  public showGenesysTelephony: boolean = false;
  public showIncomingCallNotification: boolean = false;
  showHeader: boolean = true;

  constructor(
    private readonly router: Router,
    private datePipe: DatePipe,
    public headerSettingsService: HeaderConfigService,
    private authService: MsalService,
    private authorizationService: AuthorizationService,
    private translate: TranslateService,
    private policyService: PolicyService,
    private usersService: UsersService,
    private domSanitizer: DomSanitizer,
    private eventFireService: EventFireService,
    public menuSettingsService: SideBarMenuConfigService,
    private readonly toastService: ToastService,
    private readonly notificationService: NotificationService,
    private host: ElementRef<HTMLElement>,
    private readonly utils: UtilsService,
    private searchApiService: SearchApiService,
    private location: Location,
    private templatesDataService: TemplatesDataService,
    private loanEngineApi: LoanEngineApiService,
    private apiExceptionsHandlerService: ApiExceptionsHandlerService,
    private genesysTelephonyService: GenesysTelephonyService,
    public accountDataService: AccountDataService,
    private dynamicValidationService: ValidationRulesService,
    private readonly callLogService: HorizonGenesysCallLogService,
    private zone: NgZone
  ) {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        if (event.url.includes('shortcuts')) {
          this.showHeader = false;
        }
      });
    this.menuSettingsService.setMenuColors(this.host);

    this.notificationService.getLoadingSpinner().subscribe((loading) => {
      this.showLoadingSpinner = loading;
    });

    this.toastService.getToastNotifications().subscribe((notifications) => {
      this.toastNotifications = notifications;
    });

    this.genesysTelephonyService
      .getGenesysTelephony()
      .subscribe((genesysTelephony) => {
        this.showGenesysTelephony = genesysTelephony;
      });

    this.genesysTelephonyService
      .getIncomingCallNotification()
      .subscribe((incomingCallNotification) => {
        this.showIncomingCallNotification = incomingCallNotification;
      });

    this.notificationService
      .getNotificationDialog()
      .subscribe((notifications) => {
        this.notificationsDialog = notifications;
      });
  }

  ngOnInit(): void {
    this.currentLanguage = this.translate.currentLang;
    this.setLoginDisplay();
    const currentUrl = this.location.path();
    this.getSystemDate();
    this.genesysTelephonyService.setGenesysTelephonyIcon('default');
    //TODO: this.callLogService.setGenesysSubstituteIcon('default'); uncomment if the substitute is needed again
    this.checkRouteAndSetHeaderVisibility();
    this.checkCallLogModal();

    this.getTranslations('en');
    this.getTranslations('es');
  }

  private checkCallLogModal(): void {
    const callLogModalStatus =
      this.callLogService.getLocalStorageData('modalStatus') ?? '' !== ''
        ? this.callLogService.getLocalStorageData('modalStatus')
        : false;
    if (
      callLogModalStatus &&
      callLogModalStatus.alreadyOpen === false &&
      callLogModalStatus.open === true &&
      callLogModalStatus.genesysRedirection === true
    ) {
      if (
        this.callLogService.getLocalStorageData('isCustomerOverview') === true
      ) {
        this.callLogView = 'ReportCallLogView';
      } else {
        this.callLogView = 'SearchCallLogView';
      }
      this.showCallLog = true;
      this.callLogService.setLocalStorageData('modalStatus', {
        open: true,
        alreadyOpen: true,
        genesysRedirection: true,
      });
    }
  }

  public languageChanged(language: string): void {
    this.changeLanguage(language);
  }

  private checkRouteAndSetHeaderVisibility(): void {
    if (this.router.url.includes('shortcuts')) {
      this.showHeader = false;
    }
  }

  private changeLanguage(language: string): void {
    this.eventFireService.currentLanguageChanged(language);
    this.currentLanguage = language;
  }

  public goHome(): void {
    this.router.navigate([RoutePaths.HOME]);
  }

  public logout(): void {
    this.authorizationService.logout();
  }

  private setLoginDisplay(): void {
    const allAccounts = this.authService?.instance?.getAllAccounts();
    const localAccountId = allAccounts[0]?.localAccountId;

    this.fetchUser(localAccountId);
  }

  private fetchUser(userId): void {
    this.policyService.getUserInfo().subscribe({
      next: (res) => {
        this.user = res;
        localStorage.setItem(
          'currentUserPermissions',
          JSON.stringify(this.user?.['permissions'])
        );
        this.changeLanguage(this.user.language ?? 'en');

        if (res?.userPhoto) {
          this.userPhoto = this.domSanitizer.bypassSecurityTrustResourceUrl(
            'data:image;base64,' + res.userPhoto
          );
        } else {
          this.user.userPhoto = null;
          const allAccounts = this.authService?.instance?.getAllAccounts();
          const loginDisplay = allAccounts?.length > 0;

          if (loginDisplay && allAccounts[0]?.name) {
            const splittedName = allAccounts[0]?.name.split(' ');
            this.loginShortDisplayName =
              splittedName[0][0] + splittedName[1][0];
            this.usersService.getAllScopes();
          }
        }

        this.policyService.getAllRoles().subscribe({
          next: (data) => {
            data.map((role) => {
              if (role.roleName === 'SUPERUSER') {
                this.user.roleGuid === role.groupId
                  ? localStorage.setItem('isSuperUser', 'true')
                  : localStorage.setItem('isSuperUser', 'false');
              }
            });
          },
          error: (errorResponse) => {
            this.apiExceptionsHandlerService.defaultErrorToasts(
              errorResponse,
              'policy-service'
            );
          },
        });
      },
      error: (errorResponse) => {
        this.apiExceptionsHandlerService.defaultErrorToasts(
          errorResponse,
          'policy-service'
        );
      },
    });
  }

  public confirmModal(args: any): void {
    this.notificationService.setExtraAction(args);
    this.notificationService.confirmActionClicked();
  }

  public cancelModal(): void {
    this.notificationService.cancelActionClicked();
  }

  public totalData() {
    this.searchApiService
      .getAccountsSearch()
      .pipe(takeUntil(this.destroy$))
      .subscribe((response) => {
        response.map((item) => {
          item['lastSearchDate'] = this.datePipe.transform(
            item.lastSearchDate,
            'dd/MM/yyyy HH:mm'
          );
        });
        this.dataRecentsSearch = response;
      });
  }

  public updateData(args) {
    args.pin = !args.pin;
    this.searchApiService
      .postAccountSearch(args)
      .pipe(takeUntil(this.destroy$))
      .subscribe((response) => {
        this.totalData();
      });
  }

  public selectItemClick(accountNumber): void {
    const found = this.dataRecentsSearch.find(
      (_) => _.accountNumber === accountNumber
    );
    if (found) {
      this.searchApiService
        .postAccountSearch(found)
        .pipe(takeUntil(this.destroy$))
        .subscribe((response) => {
          this.totalData();
          this.router
            .navigateByUrl('/', { skipLocationChange: true })
            .then(() => {
              this.router
                .navigate([RoutePaths.ACCOUNT, found.accountId])
                .then(() => {
                  this.templatesDataService.setTemplates({});
                  this.templatesDataService.setTemplatesReferences({});
                  this.templatesDataService.setItemsByTemplates({});
                });
            });
        });
    }
  }

  public onSearch(event: any): void {
    if (event.type === 'advanced') {
      this.router.navigate([RoutePaths.SEARCH]);
    } else if (isNaN(event.value) || event.value >= 0) {
      this.router.navigate([RoutePaths.SEARCH, event.value.trim()]);
    }
  }

  public onToolbarItemClicked(event: HeaderToolbarOption): void {
    switch (event.action) {
      case 'window_open':
        this.onWindowOpenAction(event);
        break;
      case 'genesys_telephony':
        this.onGenesysTelephonyAction(this.showGenesysTelephony, event);
        break;
      // case 'genesys_substitute':
      //   this.setGenesysSubstituteModal();
      //   break;
      //TODO: In case that the genesis substitute is needed again, uncomment the above block
      default:
        break;
    }
  }

  public onWindowOpenAction(event: any): void {
    window.open(event.url, '_blank');
  }

  public onGenesysTelephonyAction(
    showGenesysTelephony: boolean,
    event: any
  ): void {
    this.isLoginWanted = true;
    if (!showGenesysTelephony) {
      this.genesysTelephonyService.showGenesysTelephony();
      this.genesysTelephonyService.setGenesysTelephonyIcon('selected');
    } else {
      this.isLoginWanted = false;
      this.genesysTelephonyService.hideGenesysTelephony();
      this.genesysTelephonyService.setGenesysTelephonyIcon('default');
    }
  }

  private async getSystemDate(): Promise<void> {
    await this.loanEngineApi.getSystemDate().then(async (response: any) => {
      this.systemDate = response;
      this.accountDataService.setSystemDate(this.systemDate);
      this.dynamicValidationService.setSystemDate(this.systemDate);
    });
  }

  private setGenesysSubstituteModal(): void {
    this.callLogService.setGenesysSubstituteIcon('selected');
    this.showGenesysSubstituteModal = true;
  }

  public closeGenesysSubstituteModal(search: boolean, event?: Event): void {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    this.showGenesysSubstituteModal = false;
    this.callLogService.setGenesysSubstituteIcon('default');
    if (search) {
      this.zone.run(() => {
        this.searchByTelephoneNumber();
      });
    }
  }

  private searchByTelephoneNumber(): void {
    const isReportView = this.callLogService.getLocalStorageData('callLogView');
    this.callLogView =
      isReportView && isReportView === 'report'
        ? 'ReportCallLogView'
        : 'SearchCallLogView';
    this.callLogService.setLocalStorageData('modalStatus', {
      open: true,
      alreadyOpen: false,
      genesysRedirection: true,
    });
    const newWindow = window.open(RoutePaths.SEARCH, '_blank', 'noopener');
    if (newWindow) {
      newWindow.focus();
    }
  }

  public async swapCallLogModal(): Promise<void> {
    this.callLogService.setLocalStorageData('modalStatus', {
      open: false,
      alreadyOpen: false,
      genesysRedirection: false,
    });
    this.showCallLog = false;
    this.callLogView = 'ReportCallLogView';
    await setTimeout(() => {
      //? INFO: this timeout is needed to prevent the modal from opening before the view is swapped
      //? INFO: Due to the nature of the modal, it is not possible to open the modal before the view is swapped
      this.showCallLog = true;
    }, 500);
  }

  public closeCallLog() {
    this.callLogService.setLocalStorageData('modalStatus', {
      open: false,
      alreadyOpen: false,
      genesysRedirection: false,
    });
    this.showCallLog = false;
  }

  private async getTranslations(language: string): Promise<void> {
    await this.loanEngineApi
      .getTranslations(language)
      .then((response: any) => {
        localStorage.setItem(
          'loan-engine-' + language + '-translations',
          JSON.stringify(response)
        );
      })
      .catch((errorResponse) => {});
  }
}
