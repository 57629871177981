export enum RoutePaths {
  ACCOUNT = 'account',
  ACCOUNT_CHANGE = 'account-change',
  ACCOUNT_INFORMATION = 'account-information',
  ARRANGEMENT_TO_PAY = 'arrangement-to-pay',
  ARREARS = 'arrears',
  BASE_RATE_CHANGES = 'baseRateChanges',
  BREATHING_SPACE = 'breathing-space',
  CALLS = 'calls',
  COMPLAINTS = 'complaints',
  COVENANTS = 'covenants',
  CURRENT_EXPOSURE = 'current-exposure',
  CUSTOMER_CENTRIC = 'entity',
  CUSTOMER_CENTRIC_DASHBOARD = 'relationships-dashboard',
  CUSTOMER_CENTRIC_DOCUMENTS = 'documents',
  CUSTOMER_CENTRIC_KYC = 'kyc',
  CUSTOMER_CENTRIC_NOTES = 'notes',
  CUSTOMER_CENTRIC_RELATIONSHIPS = 'relationships',
  CUSTOMER_CENTRIC_VULNERABILITY = 'vulnerability',
  CUSTOMERS = 'customers',
  CUSTOMERS_FINANCIAL_CRIME = 'financial-crime',
  CUSTOMERS_GDPR = 'gdpr',
  CUSTOMERS_INSOLVENCY = 'insolvency',
  CUSTOMERS_KYC = 'customers-kyc',
  CUSTOMERS_OVERVIEW = 'customers-overview',
  CUSTOMERS_PARTICIPANTS = 'customers-participants',
  CUSTOMERS_RELATED_PARTIES = 'customers-related-parties',
  CUSTOMERS_VULNERABILITY = 'customers-vulnerability',
  DD_MANAGEMENT = 'dd-management',
  DIARIES = 'diaries-and-standing-data',
  DOCUMENTS = 'documents',
  DUE_DAY_CHANGE = 'due-day-change',
  ENTITIES = 'entities',
  ENTITIES_OVERVIEW = 'entities-overview',
  FIELD_AGENT = 'field-agent',
  FLEXIBLE_FEATURE = 'flexible-feature',
  FULL_REDEMPTIONS = 'full-redemptions',
  GOVERNMENT_SCHEME = 'government-scheme',
  GROUP_CONFIG = 'groupConfig',
  GROUP_EXPOSURE = 'GroupExposure',
  GROUPEXPOSURE_KYC = 'kyc',
  HOME = '',
  INCOME_EXPENDITURE = 'income-expenditure',
  INTEREST_CHARGE_HISTORY = 'interest-charge-history',
  INVOICE = 'invoice',
  LITIGATION = 'litigation',
  NON_STANDARD_COSTS = 'non-standard-costs',
  NOTES = 'notes',
  OVERPAYMENT = 'overpayment',
  PAYMENT_HOLIDAY = 'payment-holiday',
  PAYMENT_REVIEW = 'payment_review',
  PRODUCT_SWITCH = 'product-switch',
  RECEIVERSHIP = 'receivership',
  REFUNDS = 'refunds',
  REPAYMENT_STRATEGY = 'repayment-strategy',
  REPAYMENT_STRATEGY_EXPOSURE = 'repayment-strategy-exposure',
  RESTRUCTURE = 'restructure',
  SEARCH = 'search',
  SEARCH_CHILD = 'search/:id',
  SECURITY = 'security',
  SECURITY_ABANDONMENT = 'security-abandonment',
  SECURITY_AMENDMENTS_ORDERS = 'security-amendments-orders',
  SECURITY_DEEDS_TRACKER = 'security-deeds-tracker',
  SECURITY_DEMANDS = 'security-demands',
  SECURITY_INSURANCE = 'security-insurance',
  SECURITY_LETTINGS = 'security-lettings',
  SECURITY_OVERVIEW = 'security-overview',
  SECURITY_PARTIAL_RELEASES = 'security-partial-releases',
  SECURITY_TITLE_CHARGES = 'title-charges',
  VALUATIONS = 'security-valuation',
  SECURITY_VOLUNTARY_SURRENDER = 'security-voluntary-surrender',
  SHORTCUTS = 'shortcuts',
  SOURCE_OF_FUND_REDEMPTIONS = 'source-of-funds-redemptions',
  SUGGESTED_EXPOSURE = 'suggested-exposure',
  SUPER_USER = 'superUser',
  SUSPICIOUS = 'suspicious',
  TARIFF_OF_CHARGES = 'tariffOfCharges',
  THIRD_PARTY = 'ThirdParty',
  TRANSACTION = 'transaction',
  TRANSACTION_CODES = 'transactionCodes',
  TRANSACTIONS = 'transactions',
  UNAUTHORIZED = 'unauthorized',
}

export enum MenuRoutePaths {
  BASE_RATE_CHANGES = '/baseRateChanges',
  CUSTOMER_CENTRIC = '/entity',
  GROUP_CONFIG = '/groupConfig',
  GROUP_EXPOSURE = '/GroupExposure',
  SUPER_USER_ROLES_AND_PERMISSIONS = '/superUser/rolesAndPermissions',
  SUPER_USER_SCOPES_GROUPS = '/superUser/scopesGroups',
  SUPER_USER_USERS = '/superUser/users',
  TARIFF_OF_CHARGES = '/tariffOfCharges',
  THIRD_PARTY = '/ThirdParty',
  TRANSACTION = '/transaction',
  TRANSACTION_CODES = '/transactionCodes',
}
