import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UrlBuilder } from 'http-url-builder';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  HorizonPaymentGetawayModel,
  HorizonPaymentGetawayResponse,
} from '../models/horizon-payment-getaway.model';

@Injectable({
  providedIn: 'root',
})
export class HorizonPaymentGetawayService {
  // Private variables
  private readonly baseUrl: UrlBuilder = new UrlBuilder(
    environment.apim.baseUrl
  ).addPath(environment.apim.apiSlugs.callLogApi);

  constructor(private http: HttpClient) {}

  /**
   * Creates a payment session in PCIPal
   *
   * @param {HorizonPaymentGetawayModel} data - The data required to create the payment session
   * @returns {Observable<HorizonPaymentGetawayResponse>} An Observable containing the server response
   *
   * @description
   * This method performs a POST request to the PCIPal session endpoint
   * building the complete URL using the configured baseUrl and adding
   * the path segments 'payment/PCIPal/session'
   */
  public GeneratePCIPalSession(
    data: HorizonPaymentGetawayModel
  ): Observable<any> {
    const fullUrl: string = this.baseUrl
      .addPath('payment')
      .addPath('PCIPal')
      .addPath('session')
      .build();
    return this.http.post<HorizonPaymentGetawayResponse>(fullUrl, data);
  }

}
