import {
  Component,
  OnInit,
  OnDestroy,
  Output,
  EventEmitter,
  Input,
} from '@angular/core';
import { HorizonGenesysCallLogService } from '../../services/horizon-genesys-call-log.service';
import {
  catchError,
  EMPTY,
  filter,
  finalize,
  from,
  Subject,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';
import { BeforeCloseEventArgs } from '@syncfusion/ej2-angular-popups';
import { ApiExceptionsHandlerService } from '@shared/services/api-exceptions-handler.service';
import { WorkloadApiService } from '@horizon/core';
import { NotificationService, ToastService } from '@horizon/core-notifications';
import { Content, ReportData, ReportDataVM } from './models/';
import { TranslateService } from '@ngx-translate/core';
import { HttpErrorResponse } from '@angular/common/http';
import { HorizonPaymentGetawayService } from '../horizon-payment-getaway/services/horizon-payment-getaway.service';

@Component({
  selector: 'horizon-genesys-call-log',
  templateUrl: './horizon-genesys-call-log.component.html',
  styleUrls: ['./horizon-genesys-call-log.component.scss'],
})
export class HorizonGenesysCallLogComponent implements OnInit, OnDestroy {
  @Input() activityId: number;
  @Input() callLogView:
    | 'SearchCallLogView'
    | 'ReportCallLogView'
    | 'PCIPalView';
  @Input() isEditMode: boolean = false;
  @Output() closeEvent = new EventEmitter<any>();
  @Output() swapView = new EventEmitter<boolean>();

  public callId: string;
  public formData: any;
  public isPCIPalOpen: boolean = false;
  public phoneNumber: string;
  public paymentData: any;
  public reportData: ReportDataVM;
  public title: string;
  public isSearching: boolean = false;

  private callSubType: string;
  private callType: string;
  private readonly destroy$ = new Subject<void>();

  constructor(
    private readonly apiExceptionsHandlerService: ApiExceptionsHandlerService,
    private readonly callLogService: HorizonGenesysCallLogService,
    private readonly notificationService: NotificationService,
    private readonly toastService: ToastService,
    private readonly workloadApi: WorkloadApiService,
    public translate: TranslateService,
    private readonly paymentGetawayService: HorizonPaymentGetawayService
  ) {}

  ngOnInit(): void {
    this.callLogService.setLocalStorageData('modalStatus', {
      open: true,
      alreadyOpen: true,
      genesysRedirection: true,
    });
    this.title = 'call_log.titles.call_log_search_view';
    if (this.callLogView === 'ReportCallLogView' && !this.isEditMode) {
      this.initializeViewCallLog();
      this.title = 'call_log.titles.call_log_report_view';
    } else if (this.callLogView === 'ReportCallLogView' && this.isEditMode) {
      this.initializeCustomerOverviewReport();
      this.callLogService.setLocalStorageData('isCustomerOverview', 'false');
    }
  }

  ngOnDestroy(): void {
    this.callLogService.setLocalStorageData('modalStatus', {
      open: false,
      alreadyOpen: false,
      genesysRedirection: false,
    });
    this.callLogService.deleteLocalStorageData('GenesysData');
    this.callLogService.deleteLocalStorageData('modalStatus');
    this.destroy$.next(undefined);
    this.destroy$.complete();
    this.callLogService.deleteLocalStorageData('isCustomerOverview');
  }

  public cancelEvent(event: boolean): void {
    if (event) {
      this.close();
      window.close();
    }
  }

  /**
   * Closes the component and emits a close event.
   * @param args - Optional close event arguments.
   */
  public async close(args?: BeforeCloseEventArgs): Promise<void> {
    await sessionStorage.removeItem('modalStatus');
    this.closeEvent.emit(true);
  }

  /**
   * Handles the open event of the component.
   * @param args - The open event arguments.
   */
  public onOpen(args: any): void {
    args.preventFocus = true;
  }

  public openPCIPal(): void {
    this.paymentGetawayService
      .GeneratePCIPalSession({ linkToCall: this.callId })
      .pipe(
        filter((res) => res !== undefined),
        takeUntil(this.destroy$),
        tap((resp) => {
          this.paymentData = resp;
          this.isPCIPalOpen = true;
          this.callLogView = 'PCIPalView';
        }),
        catchError((error) => {
          this.apiExceptionsHandlerService.defaultErrorToasts(
            error,
            'Call Log'
          );
          return EMPTY;
        })
      )
      .subscribe();
  }

  /**
   * Saves the report data by making an API call and handles the response
   * @param {CallLogActivityData} reportData - The report information to be saved
   */
  public saveReportData(reportData: ReportData): void {
    this.notificationService.showLoadingSpinner();

    this.workloadApi
      .saveCallLogActivity(reportData)
      .pipe(
        tap(() => {
          this.toastService.showToastNotification({
            text: 'Call Log saved',
            type: 'success',
          });
        }),
        switchMap(() => from(this.close())),
        catchError((error: HttpErrorResponse) => {
          this.handleSaveError(error, 'Call Log');
          return EMPTY;
        }),
        finalize(() => {
          this.notificationService.stopLoadingSpinner();
        })
      )
      .subscribe({
        next: () => {
          this.toastService.showToastNotification({
            text: 'account.account_change.toastSuccessSave',
            type: 'success',
          });

          if (this.isEditMode) {
            window.close();
          }
        },
      });
  }

  /**
   * Handles any errors that occur during the save operation
   * @private
   * @param {HttpErrorResponse} error - The error response
   * @param {string} responsable - The responsable of the error
   */
  private handleSaveError(error: HttpErrorResponse, responsable: string): void {
    this.apiExceptionsHandlerService.defaultErrorToasts(error, responsable);
  }

  /**
   * Swaps the call log view by fetching new data and updating the component state.
   * @param event - The event object containing details for the new view.
   */
  public async swapCallLogView(event: {
    isCallLogReportView: boolean;
    accountId: number;
    customerId: number;
    callType: string;
    callSubtype: string;
    relationship: string;
  }): Promise<void> {
    this.isSearching = true;
    this.callType = event.callType;
    this.callSubType = event.callSubtype;

    await this.callLogService
      .getCallLogReport(
        event.customerId,
        event.accountId,
        event.callType,
        event.callSubtype,
        event.relationship
      )
      .pipe(
        filter((res) => res !== undefined),
        takeUntil(this.destroy$)
      )
      .subscribe({
        next: (resp: any) => {
          this.notificationService.showLoadingSpinner();
          this.formData = { ...resp };
        },
        error: (errorResponse) => {
          this.apiExceptionsHandlerService.defaultErrorToasts(
            errorResponse,
            'Call Log'
          );
          this.notificationService.stopLoadingSpinner();
          this.isSearching = false;
        },
        complete: () => {
          if (
            this.formData.General &&
            this.formData.General.data &&
            this.formData.ID_V_Prompt &&
            this.formData.ID_V_Prompt.data
          ) {
            this.toastService.showToastNotification({
              text: 'call_log.success.found_data',
              type: 'success',
            });
            this.swapCallLogViewCompleted();
            this.swapView.emit(event.isCallLogReportView);
            window.open(
              `/account/${event.accountId}/customers-overview`,
              '_blank'
            );
          } else {
            this.toastService.showToastNotification({
              text: 'call_log.errors.found_data',
              type: 'error',
            });
          }
          // TODO: Add a check if the data is empty, for this endpoint and the rest of the gets
          this.notificationService.stopLoadingSpinner();
          this.isSearching = false;
        },
      });
  }

  /**
   * Builds and sets the report data structure from form data
   * @private
   * @returns {Promise<void>}
   */
  private async buildReportData(): Promise<void> {
    try {
      const generalData = this.formData['General'].data.rows[0];

      const {
        scopeId,
        entityId,
        category,
        id,
        idvStatus,
        successfulContact,
        customerId,
        vulnerability,
        relationship,
        callSummary,
        callSummaryEdited,
        callClosure,
      } = generalData;

      this.reportData = new ReportDataVM({
        scopeId: scopeId?.value ?? -1,
        entityId: entityId.value.accountId,
        category: category.value,
        subEntityId: customerId.value,
        telephoneNote: '',
        entityType: '',
        callLog: {
          id: id?.value ?? -1,
          type: this.callType,
          subtype: this.callSubType,
          idVStatus: idvStatus?.value ?? 'Uncomplete',
          successfulContact: successfulContact.value,
          customerId: customerId.value,
          linkedAccounts: this.getLinkedAccountsIds(),
          additionalFields: {
            Vulnerability: vulnerability.value,
            Relationship: relationship.value,
            LinkedAccounts: this.pregenerateLinkedAccounts(),
          },
          content: [],
          callSummary: callSummary?.value ?? '',
          callSummaryEdited: callSummaryEdited?.value ?? '',
          callClosure: callClosure.value,
          callId: this.callId || '',
          phoneNumber: this.phoneNumber || '',
        },
      });

      this.callLogService.setReportData(this.reportData);

      const contentData = await this.pregenerateReportData();
      this.reportData.callLog.content = contentData;
      this.callLogService.setReportData(this.reportData);
    } catch (error) {
      this.toastService.showToastNotification({
        text: 'call_log.errors.initialized_call_log',
        type: 'error',
      });
      console.error('Error building report data:', error.message);
    }
  }

  private getLinkedAccountsIds(): Array<number> {
    return this.formData['General'].data.rows[0].linkedAccounts.value.map(
      (linkedAccount) => linkedAccount.accountId ?? linkedAccount.AccountId
    );
  }

  /**
   * Initializes the view for a call log by fetching data and handling any errors.
   */
  private initializeViewCallLog(): void {
    this.callLogService
      .getCallLogView(this.activityId)
      .pipe(
        filter((res) => res !== undefined),
        takeUntil(this.destroy$)
      )
      .subscribe({
        next: (response) => {
          this.formData = response;
          this.callLogService.setFormData(this.formData);
        },
        error: (errorResponse: HttpErrorResponse) => {
          this.apiExceptionsHandlerService.defaultErrorToasts(
            errorResponse,
            'Call Log'
          );
          console.error(errorResponse.message);
        },
        complete: () => {
          this.toastService.showToastNotification({
            text: 'call_log.success.initialized_call_log',
            type: 'success',
          });
          this.buildReportData();
        },
      });
  }

  private initializeCustomerOverviewReport(): void {
    this.callType = 'Outbound';
    this.callSubType = 'Agent';
    this.callLogService
      .getCallLogReport(
        Number(localStorage.getItem('currentCustomerId')),
        Number(localStorage.getItem('currentAccountId')),
        this.callType,
        this.callSubType,
        localStorage.getItem('currentRelationship')
      )
      .pipe(
        filter((res) => res !== undefined),
        takeUntil(this.destroy$)
      )
      .subscribe({
        next: (resp: any) => {
          this.formData = { ...resp };
        },
        error: (errorResponse) => {
          this.apiExceptionsHandlerService.defaultErrorToasts(
            errorResponse,
            'Dashboard'
          );
          this.notificationService.stopLoadingSpinner();
        },
        complete: () => {
          if (
            this.formData.General &&
            this.formData.General.data &&
            this.formData.ID_V_Prompt &&
            this.formData.ID_V_Prompt.data
          ) {
            this.toastService.showToastNotification({
              text: 'call_log.success.found_data',
              type: 'success',
            });
            this.swapCallLogViewCompleted();
          } else {
            this.toastService.showToastNotification({
              text: 'call_log.errors.found_data',
              type: 'error',
            });
          }
          this.notificationService.stopLoadingSpinner();
          this.callLogService.setLocalStorageData(
            'isCustomerOverview',
            'false'
          );
        },
      });
  }

  private pregenerateLinkedAccounts(): Array<any> {
    const linkedAccounts =
      this.formData['General'].data.rows[0].linkedAccounts.value;
    return linkedAccounts.map((linkedAccount) => {
      return {
        AccountId: linkedAccount.accountId ?? linkedAccount.AccountId,
        AccountRef: linkedAccount.accountRef ?? linkedAccount.AccountRef,
        Selected: linkedAccount.selected ?? linkedAccount.Selected,
      };
    });
  }

  /**
   * Pre-generates report data by filtering and mapping rows based on specific criteria.
   * @returns An array of objects containing specific properties extracted from the formData.
   */
  private async waitForFormData(): Promise<void> {
    while (
      !this.formData ||
      !this.formData['ID_V_Prompt'] ||
      !this.formData['ID_V_Prompt'].data
    ) {
      await new Promise((resolve) => setTimeout(resolve, 50));
    }
  }

  private async pregenerateReportData(): Promise<Array<any>> {
    await this.waitForFormData();
    const reportData: Array<Content> = this.formData['ID_V_Prompt'].data.rows
      .filter(
        (row) => row.type.value === 'ID' || row.type.value === 'Verification'
      )
      .map((row) => ({
        type: row.type.value,
        fieldDataType: row.fieldType.value,
        textId: row.textId.value,
        answer: row.answer.value || '',
        result: 'NA',
      }));

    return reportData;
  }

  /**
   * Completes the process of swapping the call log view.
   * Sets the title, form data, and builds the report data.
   */
  private swapCallLogViewCompleted(): void {
    this.title = 'call_log.titles.call_log_report_view';
    this.callLogService.setFormData(this.formData);
    this.callId = this.callLogService.getLocalStorageData('GenesysData').callID;
    this.phoneNumber =
      this.callLogService.getLocalStorageData('GenesysData').phoneNumber;
    this.buildReportData();
  }
}
