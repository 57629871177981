import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PCIPalService {
  public readonly pcipalIframeUrl: string =
    'https://cacentral1.pcipalstaging.cloud/session/461/view/';
  private readonly pcipalUrl: string =
    'wss://cacentral1.pcipalstaging.cloud/socket';
  public socket = new WebSocket(this.pcipalUrl);

  private readonly heartbeatDelay: number = 30000;
  private readonly heartbeatMessage: any = {
    type: 'keepalive',
  };

  private heartbeatInterval: NodeJS.Timer;

  constructor() {}

  public connectionOpenedEvent(accessToken: string, sessionId: string): void {
    this.socket.onopen = () => {
      console.log('Connected to the PCI Pal WebSocket server.');

      // Initial message to register with server.
      const initMessage = {
        type: 'auth',
        bearer: accessToken,
      };
      this.socket.send(JSON.stringify(initMessage));

      // Message to listen to an ongoing session.
      const listenMessage = {
        type: 'websession',
        register: sessionId,
        fullStatus: true,
      };
      this.socket.send(JSON.stringify(listenMessage));

      // Heartbeat - message to keep the socket alive.
      this.initHeartbeat();
    };
  }

  public messageReceivedEvent(): void {
    this.socket.onmessage = (event) => {
      const message = event.data;

      console.log('Received message:', message);

      // Handle PCI Pal data here
    };
  }

  public handleErrorEvent(): void {
    this.socket.onerror = (error) => {
      console.error('Error observed on PCI Pal WebSocket:', error);
    };
  }

  public connectionClosedEvent(): void {
    this.stopHeartbeat();
    this.socket.onclose = () => {
      console.log('Disconnected from the PCI Pal WebSocket server.');
    };
  }

  private initHeartbeat(): void {
    this.heartbeatInterval = setInterval(() => {
      this.socket.send(JSON.stringify(this.heartbeatMessage));
    }, this.heartbeatDelay);
  }

  private stopHeartbeat(): void {
    clearInterval(this.heartbeatInterval);
  }
}
