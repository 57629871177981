import { Component, Input, NgZone, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { GenesysTelephonyService } from './services/genesys-telephony.service';
import { RoutePaths } from '@shared/enums/routes.enums';
import { HorizonGenesysCallLogService } from '@shared/services/horizon-genesys-call-log.service';
declare function genesysTelephonyListener(): any;
declare function callInteraction(action, interactionId): any;

@Component({
  selector: 'horizon-genesys-telephony',
  templateUrl: './horizon-genesys-telephony.component.html',
  styleUrls: ['./horizon-genesys-telephony.component.scss'],
})
export class HorizonGenesysTelephonyComponent implements OnInit {
  @Input() showGenesysTelephony: boolean = false;
  @Input() showIncomingCallNotification: boolean = false;

  public genesysTelephonyUrl: SafeResourceUrl = null;
  public url: string =
    'https://apps.euw2.pure.cloud/crm/embeddableFramework.html?dedicatedLoginWindow=true';

  public interactionId: string = '';

  public phoneIcon = `<svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M18.3351 15.0994V17.5994C18.3361 17.8315 18.2886 18.0612 18.1956 18.2739C18.1026 18.4865 17.9662 18.6774 17.7952 18.8343C17.6242 18.9912 17.4223 19.1107 17.2024 19.185C16.9826 19.2594 16.7496 19.287 16.5185 19.2661C13.9542 18.9875 11.491 18.1112 9.32682 16.7078C7.31334 15.4283 5.60626 13.7212 4.32682 11.7078C2.91846 9.53377 2.04202 7.05859 1.76848 4.48276C1.74766 4.25232 1.77505 4.02006 1.8489 3.80078C1.92275 3.5815 2.04146 3.38 2.19745 3.20911C2.35345 3.03822 2.54332 2.90169 2.75498 2.8082C2.96663 2.71471 3.19543 2.66631 3.42682 2.6661H5.92682C6.33124 2.66212 6.72331 2.80533 7.02995 3.06904C7.33659 3.33275 7.53688 3.69897 7.59348 4.09943C7.699 4.89949 7.89469 5.68504 8.17682 6.4411C8.28894 6.73937 8.3132 7.06353 8.24674 7.37516C8.18028 7.6868 8.02587 7.97286 7.80182 8.19943L6.74348 9.25776C7.92978 11.3441 9.6572 13.0715 11.7435 14.2578L12.8018 13.1994C13.0284 12.9754 13.3144 12.821 13.6261 12.7545C13.9377 12.688 14.2619 12.7123 14.5601 12.8244C15.3162 13.1066 16.1018 13.3022 16.9018 13.4078C17.3066 13.4649 17.6763 13.6688 17.9406 13.9807C18.2049 14.2926 18.3453 14.6907 18.3351 15.0994Z" fill="#002841" stroke="#002841" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M12.543 5.16732C13.3569 5.32612 14.105 5.7242 14.6914 6.3106C15.2778 6.897 15.6758 7.64504 15.8346 8.45898M12.543 1.83398C14.234 2.02185 15.811 2.77913 17.0148 3.98149C18.2187 5.18385 18.978 6.75983 19.168 8.45065" stroke="#002841" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>`;

  private newWindow: Window;

  constructor(
    private ngZone: NgZone,
    private domSanitizer: DomSanitizer,
    private genesysTelephonyService: GenesysTelephonyService,
    private readonly callLogService: HorizonGenesysCallLogService
  ) {
    genesysTelephonyListener();
    window['angularComponentReference'] = {
      component: this,
      zone: this.ngZone,
      interactionSubscriptionEvent: (args) =>
        this.handleInteractionSubscriptionEvent(args),
    };
  }

  ngOnInit(): void {
    this.genesysTelephonyUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(
      this.url
    );
    this.callLogService.deleteLocalStorageData('GenesysData');
    (this.callLogService.getLocalStorageData('modalStatus') ?? '' === '') &&
      this.callLogService.setLocalStorageData('modalStatus', {
        open: true,
        alreadyOpen: false,
        genesysRedirection: false,
      });
  }

  public handleInteractionSubscriptionEvent(args: any) {
    if (args.data.category === 'change') {
      let state;
      if (args.data.interaction.old) {
        state = args.data.interaction.old.state;
        this.interactionId = args.data.interaction.old.id;
      } else {
        state = args.data.interaction.state;
        this.interactionId = args.data.interaction.id;
      }
      if (state === 'ALERTING') {
        this.genesysTelephonyService.setGenesysTelephonyIcon('incoming');
        if (!this.showGenesysTelephony) {
          this.genesysTelephonyService.showIncomingCallNotification();
        }
      }
      if ((args.data.interaction.new.calledNumber ?? '') !== '') {
        if (
          args.data.interaction.new.direction === 'Inbound' &&
          args.data.interaction.new.state === 'CONNECTED'
        ) {
          this.startInboundCall(args);
        } else if (args.data.interaction.new.direction === 'Outbound') {
          this.startOutboundCall(args);
        }
      }
    } else if (args.data.category === 'disconnect') {
      let state;
      state = args.data.interaction.state;
      if (state === 'DISCONNECTED') {
        this.checkIfCallLogIsReset();
        this.genesysTelephonyService.setGenesysTelephonyIcon('selected');
      }
    }
  }

  public answerCall(): void {
    callInteraction('pickup', this.interactionId);
    this.genesysTelephonyService.hideIncomingCallNotification();
    this.genesysTelephonyService.showGenesysTelephony();
  }

  public declineCall(): void {
    callInteraction('disconnect', this.interactionId);
    this.genesysTelephonyService.setGenesysTelephonyIcon('default');
    this.genesysTelephonyService.hideIncomingCallNotification();
  }

  private startOutboundCall(args: any): void {
    this.callLogService.setLocalStorageData('GenesysData', {
      callID: args.data.interaction.new.id,
      phoneNumber: args.data.interaction.new.calledNumber,
      callType: 'Outbound',
      callSubType: args.data.interaction.new.isDialler ? 'Dialler' : 'Agent',
    });
    if (
      this.callLogService.getLocalStorageData('modalStatus')
        .genesysRedirection !== true
    )
      this.searchByTelephoneNumber();
  }

  private startInboundCall(args: any): void {
    this.callLogService.setLocalStorageData('GenesysData', {
      callID: args.data.interaction.new.id,
      phoneNumber: args.data.interaction.new.ani,
      callType: 'Inbound',
      callSubType: null,
    });
    if (
      this.callLogService.getLocalStorageData('modalStatus')
        .genesysRedirection !== true
    )
      this.searchByTelephoneNumber();
  }

  private searchByTelephoneNumber(): void {
    if (
      this.callLogService.getLocalStorageData('modalStatus')
        .genesysRedirection !== true
    ) {
      this.callLogService.setLocalStorageData('modalStatus', {
        open: true,
        alreadyOpen: false,
        genesysRedirection: true,
      });
      if (!this.newWindow) window.open(RoutePaths.SEARCH, '_blank');
    }
  }

  private checkIfCallLogIsReset(): void {
    const modalStatus = this.callLogService.getLocalStorageData('modalStatus');
    if (
      modalStatus.open === true &&
      modalStatus.alreadyOpen === true &&
      modalStatus.genesysRedirection === true
    ) {
      this.callLogService.setLocalStorageData('modalStatus', {
        open: true,
        alreadyOpen: false,
        genesysRedirection: false,
      });
    }
  }
}
