import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { PCIPalService } from './services/PCI-Pal.service';

@Component({
  selector: 'horizon-payment-getaway',
  templateUrl: `./horizon-payment-getaway.component.html`,
  styleUrls: ['./horizon-payment-getaway.component.scss'],
})
export class HorizonPaymentGetawayComponent implements OnInit, OnDestroy {
  @Input() paymentData: any;


  public showPCIPal: boolean = false;
  public iframeActionUrl: string = '';

  public sessionId: string;
  public accessToken: string;
  public refreshToken: string;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(private PCIPalService: PCIPalService) {}

  ngOnInit(): void {
    [this.accessToken, this.sessionId] = this.paymentData;
    this.generateIframeActionURL();
    this.PCIPalService.connectionOpenedEvent(this.accessToken, this.sessionId);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.PCIPalService.connectionClosedEvent();
  }

  private generateIframeActionURL(): void {
    this.iframeActionUrl = this.PCIPalService.pcipalIframeUrl + this.sessionId + '/framed';
  }
}
