<div class="genesys-call-log-container" *ngIf="areFormsInitialized">
  <!-- #region LEFT PANEL CALL LOG-->
  <div class="genesys-call-log__left">
    <!-- #region GENERAL CALL LOG FORM-->
    <form class="genesys-call-log__form-group" [formGroup]="generalForm">
      <div class="genesys-call-log__field">
        <horizon-text-input
          [disabled]="true"
          [label]="'call_log.call_log_report_view.general.direction'"
          [iconName]="generalFormIcon.direction.iconName"
          [iconColor]="generalFormIcon.direction.iconColor"
          [name]="'direction'"
          [hasIconLink]="true"
          [iconSize]="'16'"
          [control]="generalForm?.controls['direction']"
        ></horizon-text-input>
      </div>
      <div class="genesys-call-log__field">
        <horizon-text-input
          [disabled]="true"
          name="id_v"
          [iconName]="generalFormIcon.id_v.iconName"
          [iconGroup]="generalFormIcon.id_v.iconGroup"
          [iconColor]="generalFormIcon.id_v.iconColor"
          [hasIconLink]="true"
          [iconSize]="'16'"
          [label]="'call_log.call_log_report_view.general.id'"
          [control]="generalForm?.controls['id_v']"
        ></horizon-text-input>
      </div>
      <div class="genesys-call-log__field genesys-call-log__field--full">
        <horizon-text-input
          [disabled]="true"
          name="vulnerability"
          [iconName]="generalFormIcon.vulnerability.iconName"
          [iconGroup]="generalFormIcon.vulnerability.iconGroup"
          [iconColor]="generalFormIcon.vulnerability.iconColor"
          [hasIconLink]="true"
          [iconSize]="'16'"
          [label]="'call_log.call_log_report_view.general.vulnerability'"
          [control]="generalForm?.controls['vulnerability']"
        ></horizon-text-input>
      </div>
      <div class="genesys-call-log__field genesys-call-log__field--full">
        <horizon-dropdown
          [enable]="true"
          [label]="'call_log.call_log_report_view.general.category'"
          [dropdownValues]="categoryOptions"
          [fields]="categoryOptionsValues"
          [showClearButton]="false"
          [filtering]="false"
          [control]="generalForm?.controls['category']"
          (valueSelected)="categoryChanged($event)"
        >
        </horizon-dropdown>
      </div>
      <div class="genesys-call-log__field">
        <horizon-text-input
          [disabled]="true"
          name="callFromTo"
          [label]="'call_log.call_log_report_view.general.call_from_to'"
          [control]="generalForm?.controls['callFromTo']"
        ></horizon-text-input>
      </div>
      <div class="genesys-call-log__field">
        <horizon-text-input
          *ngIf="!isAgentCalling || !isEditMode"
          [disabled]="true"
          name="successfulContact"
          [label]="'call_log.call_log_report_view.general.successful_contact'"
          [control]="generalForm?.controls['successfulContact']"
        ></horizon-text-input>
        <horizon-dropdown
          *ngIf="isAgentCalling && isEditMode"
          class="inputs__item"
          [label]="'call_log.call_log_report_view.general.successful_contact'"
          [dropdownValues]="successfulContactOptions"
          [value]="generalFormData.rows[0].successfulContact?.value"
          [fields]="successfulContactOptionsValues"
          [showClearButton]="true"
          [filtering]="false"
          [enabled]="true"
          (valueSelected)="successfulContactChanged($event)"
        ></horizon-dropdown>
      </div>
      <div class="genesys-call-log__field">
        <horizon-text-input
          [disabled]="true"
          name="accountNumber"
          [label]="'call_log.call_log_report_view.general.account_number'"
          [control]="generalForm?.controls['accountNumber']"
        ></horizon-text-input>
      </div>
      <div class="genesys-call-log__field">
        <horizon-text-input
          [disabled]="true"
          name="relationship"
          [label]="'call_log.call_log_report_view.general.relationship'"
          [control]="generalForm?.controls['relationship']"
        ></horizon-text-input>
      </div>
      <div class="genesys-call-log__field genesys-call-log__field--full">
        <label>{{
          "call_log.call_log_report_view.general.linked_account" | translate
        }}</label>
        <ul class="genesys-call-log__linked-accounts">
          <ng-container *ngFor="let linkedAccount of linkedAccounts">
            <li class="linked-account">
              <horizon-checkbox-input
                [disabled]="!isEditMode"
                [checked]="linkedAccount.Selected"
                (valueClicked)="selectLinkedAccount(linkedAccount)"
              ></horizon-checkbox-input>
              <span class="linked-account__text">
                {{
                  (linkedAccount.accountRef ?? "") !== ""
                    ? linkedAccount.accountRef
                    : linkedAccount.AccountRef
                }}
              </span>
            </li>
          </ng-container>
        </ul>
      </div>
    </form>
    <!-- #endregion -->

    <!-- #region Id CALL LOG ACCORDION-->
    <accordion-section [isOpen]="true">
      <!-- #region Id CALL LOG ACCORDION TITLE -->
      <ng-container accordion-title>
        <span>{{ "call_log.call_log_report_view.id.label" | translate }}</span>
        <horizon-notification-msg
          [type]="'info'"
          [text]="'call_log.call_log_report_view.id.prompt'"
        ></horizon-notification-msg>
      </ng-container>
      <!-- #endregion -->

      <!-- #region Id CALL LOG ACCORDION CONTENT -->
      <form
        accordion-content
        class="genesys-call-log__form-group"
        [formGroup]="identificationForm"
      >
        <ng-container
          *ngFor="let identificationField of identificationFormGroupFields"
        >
          <div
            class="genesys-call-log__field"
            *ngIf="(identificationField.answer?.value ?? '') !== ''"
          >
            <verification-label
              [label]="identificationField[currentLanguage].value"
              [isRequired]="false"
              [isDisabled]="!isEditMode"
              [isChecked]="false"
              [valueSelected]="identificationField.result?.value"
              [labelFor]="identificationField.textId.value"
              (switchChanged)="switchChanged($event, 'Id')"
            ></verification-label>

            <ng-container [ngSwitch]="identificationField.fieldType.value">
              <horizon-text-input
                *ngSwitchCase="'String'"
                [disabled]="true"
                [name]="identificationField.textId.value"
                [control]="
                  getControl(
                    identificationForm,
                    identificationField.textId.value
                  )
                "
              ></horizon-text-input>

              <horizon-datepicker
                *ngSwitchCase="'Date' || 'DateTime'"
                [format]="'dd/MM/yyyy'"
                [enabled]="false"
                [name]="identificationField.textId.value"
                [control]="
                  getControl(
                    identificationForm,
                    identificationField.textId.value
                  )
                "
              ></horizon-datepicker>

              <horizon-number-input
                *ngSwitchCase="'Int'"
                [showSpinButton]="false"
                [enabled]="true"
                [name]="identificationField.textId.value"
                [control]="
                  getControl(
                    identificationForm,
                    identificationField.textId.value
                  )
                "
              ></horizon-number-input>

              <horizon-masked-text-input
                *ngSwitchCase="'password'"
                [isRequired]="false"
                [enabled]="false"
                [name]="identificationField.textId.value"
                [control]="
                  getControl(
                    identificationForm,
                    identificationField.textId.value
                  )
                "
              ></horizon-masked-text-input>
            </ng-container>
          </div>
        </ng-container>
      </form>
      <!-- #endregion -->
    </accordion-section>
    <!-- #endregion -->

    <!-- #region VERIFICATION CALL LOG ACCORDION -->
    <accordion-section [isOpen]="true">
      <!-- #region VERIFICATION CALL LOG ACCORDION TITLE -->
      <ng-container accordion-title>
        <span>{{
          "call_log.call_log_report_view.verification.label" | translate
        }}</span>
        <horizon-notification-msg
          [type]="'info'"
          [text]="'call_log.call_log_report_view.verification.prompt'"
        ></horizon-notification-msg>
      </ng-container>
      <!-- #endregion -->
      <!-- #region VERIFICATION CALL LOG ACCORDION CONTENT -->
      <form
        accordion-content
        class="genesys-call-log__form-group"
        [formGroup]="verificationForm"
      >
        <ng-container
          *ngFor="let verificationField of verificationFormGroupFields"
        >
          <div
            class="genesys-call-log__field"
            *ngIf="(verificationField.answer?.value ?? '') !== ''"
          >
            <verification-label
              [isChecked]="false"
              [isDisabled]="!isEditMode"
              [isRequired]="false"
              [label]="verificationField[currentLanguage].value"
              [valueSelected]="verificationField.result?.value"
              [labelFor]="verificationField.textId.value"
              (switchChanged)="switchChanged($event, 'Verification')"
            ></verification-label>

            <ng-container [ngSwitch]="verificationField.fieldType.value">
              <horizon-text-input
                *ngSwitchCase="'String'"
                [disabled]="true"
                [name]="verificationField.textId.value"
                [control]="
                  getControl(verificationForm, verificationField.textId.value)
                "
              ></horizon-text-input>

              <horizon-datepicker
                *ngSwitchCase="'Date' || 'DateTime'"
                [enabled]="false"
                [format]="'dd/MM/yyyy'"
                [name]="verificationField.textId.value"
                [control]="
                  getControl(verificationForm, verificationField.textId.value)
                "
              ></horizon-datepicker>

              <horizon-number-input
                *ngSwitchCase="'Int'"
                class="inputs__item"
                [enabled]="false"
                [name]="verificationField.textId.value"
                [showSpinButton]="false"
                [control]="
                  getControl(verificationForm, verificationField.textId.value)
                "
              ></horizon-number-input>

              <horizon-masked-text-input
                *ngSwitchCase="'password'"
                [isRequired]="false"
                [enabled]="false"
                [name]="verificationField.textId.value"
                [control]="
                  getControl(verificationForm, verificationField.textId.value)
                "
              ></horizon-masked-text-input>
            </ng-container>
          </div>
        </ng-container>
      </form>
      <!-- #endregion -->
    </accordion-section>
    <!-- #endregion -->

    <!-- #region LEFT PANEL CALL LOG BUTTONS-->
    <div class="genesys-call-log__buttons">
      <horizon-button
        [isProgressButton]="false"
        [isDisabled]="true"
        class="payment-button"
        [text]="'call_log.call_log_report_view.buttons.make_payment'"
        type="info"
        [buttonColor]="globalSettings.colors.mainColor"
        (click)="openPCIPalAction()"
      ></horizon-button>
      <!-- Change the isDisabled to a condition when the payment is implemented -->
      <div class="modal-buttons">
        <horizon-button
          [isProgressButton]="false"
          [isDisabled]="disableCancelButton()"
          [text]="'call_log.call_log_report_view.buttons.cancel'"
          [type]="'cancel'"
          [buttonColor]="globalSettings.colors.errorColor"
          (click)="cancelEvent.emit(true)"
        ></horizon-button>
        <horizon-button
          [isProgressButton]="false"
          [isDisabled]="disableSaveButton()"
          [text]="'call_log.call_log_report_view.buttons.save'"
          type="confirm"
          [buttonColor]="globalSettings.colors.successColor"
          (click)="saveData()"
        ></horizon-button>
      </div>
    </div>
    <!-- #endregion -->
  </div>
  <!-- #endregion -->

  <!-- #region RIGHT PANEL CALL LOG-->
  <div class="genesys-call-log__right">
    <div class="genesys-call-log__content">
      <horizon-textarea
        [label]="'call_log.call_log_report_view.general.telephone_note'"
        [isAutoSize]="false"
        [isCustomSize]="true"
        [size]="'300px'"
        [isDisabled]="!isEditMode"
        [value]="telephoneNote"
        (valueChange)="textAreaChange('TelephoneNote', $event)"
      ></horizon-textarea>
    </div>
    <div class="genesys-call-log__content">
      <horizon-textarea
        [label]="'call_log.call_log_report_view.general.call_summary'"
        [isAutoSize]="false"
        [isCustomSize]="true"
        [size]="'300px'"
        [isDisabled]="!isEditMode && !canUserEditCallSummary"
        [value]="callSummary"
        (valueChange)="textAreaChange('CallSummaryEdited', $event)"
      ></horizon-textarea>
    </div>
    <div class="genesys-call-log__content">
      <div class="genesys-call-log__prompts" *ngFor="let prompt of prompts">
        <horizon-notification-msg
          [type]="'info'"
          [text]="prompt[currentLanguage].value"
          class="notification-messages"
        ></horizon-notification-msg>
      </div>
    </div>
    <div class="genesys-call-log__content">
      <horizon-dropdown
        class="inputs__item"
        [label]="'call_log.call_log_report_view.general.call_closure'"
        [dropdownValues]="callClosureOptions"
        [fields]="callClosureOptionsValues"
        [enabled]="isEditMode"
        [control]="generalForm?.controls['callClosure']"
        (valueSelected)="callClosureChanged($event)"
      ></horizon-dropdown>
      <horizon-button
        [isProgressButton]="false"
        [text]="'call_log.call_log_report_view.buttons.confirm_call_closure'"
        [type]="'info'"
        [isDisabled]="
          !isEditMode || !callClosureCodeSelected || !categorySelected
        "
        [buttonColor]="globalSettings.colors.mainColor"
        (clickEvent)="populateCallSummary()"
      ></horizon-button>
    </div>
  </div>
  <!-- #endregion -->
</div>