import { Injectable } from '@angular/core';
import {
  HeaderSettings,
  HeaderToolbarOption,
  UserMenu,
} from '@horizon/core-layouts';
import { globalSettings } from 'src/global-settings';

@Injectable({
  providedIn: 'root',
})
export class HeaderConfigService {
  public headerSettings: HeaderSettings;
  public toolbarOptions: HeaderToolbarOption[] = [];
  public customMenuOptions: UserMenu;

  constructor() {
    this.headerSettings = {
      showLogoImg: true,
      logoImg: globalSettings.logo,
      showSearch: true,
      showBreadcrumbs: false,
      showToolbar: true,
      showUserAvatar: true,
      showUserOptionsMenu: true,
    };

    this.toolbarOptions = [
      {
        action: 'window_open',
        url: 'https://genchat-genchat-prod-uks.pepperhorizon.com/general',
        customSVG: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3 11.5C2.99656 12.8199 3.30493 14.1219 3.9 15.3C4.60557 16.7118 5.69025 17.8992 7.03255 18.7293C8.37485 19.5594 9.92176 19.9994 11.5 20C12.8199 20.0035 14.1219 19.6951 15.3 19.1L21 21L19.1 15.3C19.6951 14.1219 20.0034 12.8199 20 11.5C19.9994 9.92179 19.5594 8.37488 18.7293 7.03258C17.8992 5.69028 16.7117 4.6056 15.3 3.90003C14.1219 3.30496 12.8199 2.99659 11.5 3.00003H11C8.91565 3.11502 6.94696 3.99479 5.47086 5.47089C3.99476 6.94699 3.11499 8.91568 3 11V11.5Z" stroke="#DF0024" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>`,
      },
      {
        action: 'genesys_telephony',
        url: '',
        customSVG: '',
      },
      // {
      //   action: 'genesys_substitute',
      //   url: '',
      //   customSVG: '',
      // },
      //TODO: In case that the genesis substitute is needed again, uncomment the above block
    ];

    this.customMenuOptions = {
      showCustomMenu: true,
      showLanguage: true,
      showLogout: true,
      showSettings: false,
      showProfile: false,
      language: {
        en: {
          text: 'language.english',
        },
        es: {
          text: 'language.spanish',
        },
        actions: ['changeLanguage'],
      },
      logout: {
        icon: 'logout',
        text: 'actions.log_out',
        actions: ['logout'],
      },
    };
  }

  public configHeaderSettings(headerSettings: HeaderSettings): void {
    this.headerSettings = headerSettings;
  }
}
